<template>
    <router-view></router-view>    
</template>
<script>
export default {
  name: "LocateView",
  fetch: {
    backoffice: {
      model() {
        return isNaN(this.$route.params.catalogue) ? "profiler" : "salepoint";
      },
      method: "getById",
      params() {
        return this.$route.params.catalogue
          ? [this.$route.params.catalogue]
          : null;
      },
    },
  },
  watch: {
    backoffice: {
      handler() {
        if (this.backoffice && !this.$route.params.locale) {
          const defaultLnag =
            this.backoffice.defaultLang || this.backoffice.langs[0] || "en";
          console.log("set default language to", defaultLnag);
          this.$router.replace(
            `/${this.$route.params.catalogue}/${defaultLnag}`
          );
        }
      },
      immediate: true,
    },
  },
};
</script>